/* Fonts */
@font-face {
  font-family: "DM Sans";
  font-weight: 900;
  src: url("./static/fonts/DMSans-Black.ttf");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 900;
  font-style: italic;
  src: url("./static/fonts/DMSans-BlackItalic.ttf");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 800;
  src: url("./static/fonts/DMSans-ExtraBold.ttf");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 800;
  font-style: italic;
  src: url("./static/fonts/DMSans-ExtraBoldItalic.ttf");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 700;
  src: url("./static/fonts/DMSans-Bold.ttf");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 700;
  font-style: italic;
  src: url("./static/fonts/DMSans-BoldItalic.ttf");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 600;
  src: url("./static/fonts/DMSans-SemiBold.ttf");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 600;
  font-style: italic;
  src: url("./static/fonts/DMSans-SemiBoldItalic.ttf");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 500;
  src: url("./static/fonts/DMSans-Medium.ttf");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 500;
  font-style: italic;
  src: url("./static/fonts/DMSans-MediumItalic.ttf");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 400;
  src: url("./static/fonts/DMSans-Regular.ttf");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 400;
  font-style: italic;
  src: url("./static/fonts/DMSans-Italic.ttf");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 300;
  src: url("./static/fonts/DMSans-Thin.ttf");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 300;
  font-style: italic;
  src: url("./static/fonts/DMSans-ThinItalic.ttf");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 200;
  src: url("./static/fonts/DMSans-ExtraLight.ttf");
}

@font-face {
  font-family: "DM Sans";
  font-weight: 200;
  font-style: italic;
  src: url("./static/fonts/DMSans-ExtraLightItalic.ttf");
}

/* Global Styles */
html, body {
  padding: 0;
  margin: 0;
  font-family: DM Sans;
}

*:not(html):not(body) {
  scrollbar-width: thin;
  scrollbar-color: #404e5e transparent;

  &::-webkit-scrollbar {
    width: 0.6rem;

    &-track {
      border-radius: 1.6rem;
    }
    &-thumb {
      border-radius: 1.6rem;
      background-color: #404e5e;

      &:hover {
        background-color: #424242;
      }
    }
  }
}
